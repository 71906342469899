:root {
  --primary: #5980dc;
  --dark: #2e3648;
  --default: #344675;
  --secondary: #454a56;
  --success: #1aa243;
  --info: #1d8cf8;
  --warning: #fec001;
  --orange-color: #f96332;
  --danger: #f34249;
  --black: #222a42;
  --white: #ffffff;

  /* Color system */
  --gray-100: #f6f9fc;
  --gray-200: #e9ecef;
  --gray-300: #e3e3e3;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d; /* Line footer color */
  --gray-700: #525f7f; /* Line p color */
  --gray-800: #32325d; /* Line heading color */
  --gray-900: #212529;
  --link-disabled: #666666;
  --transparent-bg: transparent;
  --light-bg: #f5f6fa;
  --dark-background: #555555;

  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;

  --line-height-lg: 1.625rem;
  --line-height-sm: 1.5;
  --line-height: 1.35em;

  --opacity-gray-3: rgba(222, 222, 222, 0.3);
  --opacity-gray-5: rgba(222, 222, 222, 0.5);
  --opacity-gray-8: rgba(222, 222, 222, 0.8);

  --opacity-5: rgba(255, 255, 255, 0.5);
  --opacity-6: rgba(255, 255, 255, 0.6);
  --opacity-8: rgba(255, 255, 255, 0.8);

  --opacity-1: rgba(255, 255, 255, 0.1);
  --opacity-2: rgba(255, 255, 255, 0.2);

  --default-color-opacity: rgba(182, 182, 182, 0.6);

  /* gradient */
  --default-states: #263148;
  --primary-states: #ba54f5;
  --success-states: #0098f0;
  --info-states: #3358f4;
  --warning-states: #ff6491;
  --danger-states: #ec250d;
  --black-states: #1d253b;

  /* opacity */
  --default-opacity: rgba(182, 182, 182, 0.6);
  --primary-opacity: rgba(249, 99, 50, 0.3);
  --success-opacity: rgba(24, 206, 15, 0.3);
  --info-opacity: rgba(44, 168, 255, 0.3);
  --warning-opacity: rgba(255, 178, 54, 0.3);
  --danger-opacity: rgba(255, 54, 54, 0.3);

  --light-gray: #e3e3e3;
  --medium-gray: #dddddd;
  --dark-gray: #9a9a9a;
  --card-stats-gray: #808080;
  --active-gray: #777777;
  --nav-gray: #444444;
  --search-gray: #b7b7b7;
  --calendar-gray: #9499a7;
  --active-blue: #00bbff;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
}
.form-group,
td,
th,
.ant-input,
.ant-drawer,
.ant-col,
body {
  font-family: "Nunito", sans-serif !important;
}
.wallet-adapter-button-trigger {
  background: var(--white) !important;
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
}
a {
  font-weight: var(--font-weight-semibold);
}
.linkHover:hover {
  text-decoration: underline;
}

/* pointer */
.cursorPointer {
  cursor: pointer !important;
}
.cursorDefault {
  cursor: default !important;
}
.positionRelative {
  position: relative !important;
}
.positionAbsolute {
  position: absolute !important;
}
.positionUnset {
  position: unset !important;
}
/* /pointer */
/* line height */
.lineHeightNormal {
  line-height: normal !important;
}
.lineHeightInitial {
  line-height: initial !important;
}
.lineHeightInherit {
  line-height: inherit !important;
}
/* /line height */
/* form */
.ant-select-selector,
.ant-picker,
.ant-select {
  min-height: calc(2.375rem + 2px);
}
.ant-picker,
.ant-select .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}
.ant-select .ant-select-selector .ant-select-selection-search-input {
  height: 38px !important;
}
.ant-select .ant-select-selection-item-content {
  font-weight: var(--font-weight-medium);
}
textarea.form-control {
  border-radius: 4px !important;
}
.card form label + .form-control {
  margin-bottom: 0;
}
.form-group:last-child {
  margin: 0 0 10px 0;
}
/* /form */
/* pagination */
.ant-pagination .ant-pagination-item-active {
  border-color: var(--primary);
}
.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-item-active > a {
  color: var(--primary);
}
/* /pagination */
/* overflow */
.overflowAuto {
  overflow: auto !important;
}
.overflowHidden {
  overflow: hidden !important;
}
/* /overflow */
/* font-weight */
.font-weight-light {
  font-weight: var(--font-weight-light);
}
.font-weight-regular {
  font-weight: var(--font-weight-regular);
}
.font-weight-medium {
  font-weight: var(--font-weight-medium);
}
.font-weight-semibold {
  font-weight: var(--font-weight-semibold);
}
.font-weight-bold {
  font-weight: var(--font-weight-bold);
}
.font-weight-extrabold {
  font-weight: var(--font-weight-extrabold);
}
.font-weight-black {
  font-weight: var(--font-weight-black);
}
/* /font-weight */
/* wordBreak */
.wordBreak {
  word-break: break-word !important;
}
.wordBreakUnset {
  word-break: unset !important;
}
.wordBreakInitial {
  word-break: initial !important;
}
.whiteSpaceNowrap {
  white-space: nowrap !important;
}
/* /wordBreak */
/* image */
.object-cover {
  object-fit: cover !important;
}
/* /image */
/* attrArea */
.attrArea .attBox:not(:first-child) .attAMore {
  display: none;
}
.attrArea .attBox:not(:last-child) {
  margin-bottom: 8px;
}
.attrArea .attBox .attBIn {
  flex: 1;
}
/* /attrArea */
/* ant-tabs */
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  background-color: #f0f0f0;
  padding: 0.827rem 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ant-tabs
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: var(--dark);
  font-size: 15px;
  font-family: "Nunito", sans-serif !important;
  font-weight: var(--font-weight-semibold);
}
.ant-tabs
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--primary);
}
.ant-tabs
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--white);
}
.ant-tabs .ant-tabs-ink-bar {
  background: var(--primary);
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 5px;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
/* /ant-tabs */
/* ant-table */
.ant-table-wrapper .ant-table {
  scrollbar-color: unset !important;
}
.ant-table-wrapper .ant-table .ant-table-content {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ant-table-wrapper .ant-table .ant-table-content .ant-table-content {
  overflow-x: unset;
  -webkit-overflow-scrolling: unset;
}
.ant-table-wrapper
  .ant-table
  .ant-table-content
  table
  tbody.ant-table-tbody
  > tr.ant-table-expanded-row
  .ant-table {
  margin-block: -4px !important;
}
.ant-table-wrapper
  .ant-table
  .ant-table-content
  table
  tbody.ant-table-tbody
  > tr.ant-table-expanded-row
  .ant-table
  table
  tr
  th,
.ant-table-wrapper
  .ant-table
  .ant-table-content
  table
  tbody.ant-table-tbody
  > tr.ant-table-expanded-row
  .ant-table
  table
  tr
  td {
  background: rgba(0, 0, 0, 0.02);
}
/* /ant-table */
/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-corner {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(113, 107, 115, 0.6);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(113, 107, 115, 0.7);
}
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body *::-webkit-scrollbar-thumb {
  background: rgba(206, 200, 207, 0.55);
}
body *::-webkit-scrollbar-thumb:hover {
  background: rgba(206, 200, 207, 0.75);
}
/* /custom scrollbar */
/* ellipsis */
.ellipsisL1 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL2 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL3 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisL4 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ellipsisParent {
  overflow: hidden;
  max-width: calc(100% - 1px);
  text-overflow: ellipsis;
}
.ellipsisChild {
  /* max-width: 180px; */
  overflow: hidden;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
/* /ellipsis */
/* svgAnimationYX  */
@keyframes svgAnimationYX {
  0% {
    transform: translate(0, 0) rotate(-45deg);
  }
  50% {
    transform: translate(5px, -4px) rotate(-45deg);
  }
  100% {
    transform: translate(0, 0) rotate(-45deg);
  }
}
/* svgAnimationY  */
@keyframes svgAnimationY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
/* svgAnimationX  */
@keyframes svgAnimationX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* table-row-expand-icon */
.ant-table-wrapper .ant-table-row-expand-icon {
  width: 23px;
  height: 23px;
  line-height: 22px;
  border-color: rgba(0, 0, 0, 0.18);
}
.ant-table-wrapper .ant-table-row-expand-icon:before {
  top: 10px;
  width: 11px;
  inset-inline-start: 5px;
}
.ant-table-wrapper .ant-table-row-expand-icon:after {
  top: 5px;
  height: 11px;
  inset-inline-start: 10px;
}
.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover {
  color: var(--primary);
}
/* /table-row-expand-icon */
/* placeholder  */
::-webkit-input-placeholder {
  color: var(--gray-500) !important;
  font-weight: var(--font-weight-medium);
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
}
::-moz-placeholder {
  color: var(--gray-500) !important;
  font-weight: var(--font-weight-medium);
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
}
:-ms-input-placeholder {
  color: var(--gray-500) !important;
  font-weight: var(--font-weight-medium);
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
}
:-moz-placeholder {
  color: var(--gray-500) !important;
  font-weight: var(--font-weight-medium);
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
}
.ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: var(--gray-500) !important;
  font-weight: var(--font-weight-medium);
  font-family: "Nunito", sans-serif !important;
  font-size: 14px;
}
/* /placeholder  */
/* myAccount  */
.accountPage {
  background: var(--white);
}
.accountPage #root {
  min-height: 100vh;
}
/* /myAccount  */
