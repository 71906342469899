.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}
.merchantInfo {
  padding: 11px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid rgba(34, 42, 66, 0.05);
  & .imText {
    position: relative;
    top: 2px;
    & h4 {
      color: $dark !important;
      font-weight: $font-weight-bold;
      line-height: 1;
    }
    & span {
      line-height: 1;
    }
  }
  & .imImg {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    border-radius: 50rem;
    border: 2px solid $white;
    box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.15);
    background-image: url(../../../img/img__holder.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50rem;
    }
  }
}
.sidebar-mini {
  & .merchantInfo {
    & .sideMerchantName,
    span {
      opacity: 0;
      visibility: hidden;
      width: max-content;
      white-space: nowrap;
      display: none;
    }
  }
}
.sidebar-wrapper ul li div.collapse ul li div.collapse ul li a,
.sidebar-wrapper ul li div.collapse ul li div.collapsing ul li a,
.sidebar-wrapper ul li div.collapsing ul li div.collapse ul li a {
  margin-left: 25px;
}
// navBottom
.sidebar {
  & .navBottom {
    padding: 20px 15px;
    border-top: 0.0625rem solid rgba(34, 42, 66, 0.05);
    & .soBtn {
      & > .btnText {
        display: block;
        white-space: nowrap;
      }
    }
    & .nbAppicn {
      & > a {
        margin: 0 5px;
        color: $primary;
        &:hover {
          & i {
            transition: all 300ms ease 0s;
            color: $dark;
          }
        }
        & i {
          font-size: 22px;
        }
      }
    }
  }
}
.sidebar-mini {
  & .sidebar {
    & .navBottom {
      padding-left: 10px;
      padding-right: 10px;
      & .soBtn {
        & > .btnText {
          display: none;
        }
        & > svg {
          margin-left: 0 !important;
        }
      }
    }
  }
}
// /navBottom
.sidebar,
.off-canvas-sidebar {
  @include linear-gradient($primary-states, $primary);
  height: calc(100vh - 90px);
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  display: block;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
  margin-top: 73px;
  border-top-right-radius: 0.2857rem;
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    width: $full-width;
    min-height: calc(100% - 73px);
    z-index: 4;
    position: relative;
    overflow: auto;
    .dropdown .dropdown-backdrop {
      display: none !important;
    }
    .navbar-form {
      border: none;
    }
    > .nav,
    .user .info {
      [data-toggle="collapse"] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }
        .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
          line-height: 23px;
          z-index: 1;
          color: $opacity-8;
        }
        .sidebar-mini-icon {
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0px;
          font-size: 12px;
          text-align: center;
          line-height: 25px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          line-height: 24px;
          color: $opacity-8;
        }
        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
    [data-toggle="collapse"] ~ div > ul > li:hover > a {
      .sidebar-mini-icon,
      .sidebar-normal {
        color: $white;
      }
    }
    .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon {
      opacity: 0;
    }
  }
  .navbar-minimize {
    position: absolute;
    right: 20px;
    top: 2px;
    opacity: 1;
    @extend .animation-transition-general;
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .nav {
    padding: 15px 15px 0 15px;
    display: block;
    flex: 1;
    .caret {
      top: 14px;
      position: absolute;
      right: 10px;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 2px;
      }
      > a + div .nav {
        margin-top: 5px;
        li > a {
          margin-top: 0px;
          padding: 8px 8px;
        }
      }
      > a {
        border-radius: 8px;
        color: $black;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: $font-size-xs;
        padding: 10px;
        line-height: $line-height-lg;
        width: 100%;
      }
      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        background: rgb(52 70 117 / 20%);
        p,
        i {
          color: $white;
        }
      }
      &:hover:not(.active) > a i,
      &:focus:not(.active) > a i {
        color: $white;
        background: default;
      }
      &.active > a:not([data-toggle="collapse"]) {
        background-color: $primary;
        i,
        p {
          color: $white;
        }
      }
      &.active > a[data-toggle="collapse"] {
        background: transparent;
        box-shadow: none;
        color: $white;
        i {
          color: $white;
        }
        & + div .nav .active a {
          box-shadow: none;
          .sidebar-mini-icon,
          .sidebar-normal {
            color: $white;
            font-weight: $font-weight-regular;
          }
          &:before {
            content: " ";
            position: absolute;
            height: 6px;
            width: 6px;
            top: 17px;
            left: -4px;
            background: $white;
            border-radius: 50%;
          }
        }
        &:before {
          content: " ";
          position: absolute;
          height: 6px;
          width: 6px;
          top: 22px;
          left: -4px;
          background: rgba($white, 0.6);
          border-radius: 50%;
        }
      }
    }
    p {
      margin: 0;
      margin-right: 10px;
      font-weight: $font-weight-medium;
      font-size: 16px;
      text-transform: capitalize;
      line-height: 30px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      color: $opacity-6;
      @extend .animation-transition-general;
    }
    i {
      font-size: 18px;
      float: left;
      margin-right: 10px;
      margin-left: 5px;
      line-height: 30px;
      width: 26px;
      height: 26px;
      text-align: center;
      color: $opacity-6;
      position: relative;
    }
  }
  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: $full-width;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    &:after {
      position: absolute;
      z-index: 3;
      width: $full-width;
      height: 100%;
      content: "";
      display: block;
      background: #ffffff;
      opacity: 1;
    }
  }
  .logo {
    position: relative;
    padding: 20px 22px;
    z-index: 4;
    display: inline-flex;
    align-items: center;
    a.logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }
    a.logo-mini {
      opacity: 1;
      float: left;
      width: 34px;
      text-align: center;
      margin-right: 10px;
    }
    a.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 260px;
      background: rgb(34 42 66 / 15%);
    }
    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white;
      line-height: 20px;
      font-family: "Nunito", sans-serif;
    }
    .simple-text {
      display: block;
      white-space: nowrap;
      font-weight: $font-weight-semibold;
      font-size: $font-size-lg;
      color: $black;
      text-decoration: none;
      line-height: 30px;
      overflow: hidden;
    }
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
    }
  }
  &[data="blue"] {
    background: $card-black-background;
    &:before {
      border-bottom-color: $default;
    }
  }
  &[data="green"] {
    @include linear-gradient($success-states, $success);
    &:before {
      border-bottom-color: $success;
    }
  }
  &[data="orange"] {
    @include linear-gradient($warning-states, $warning);
    &:before {
      border-bottom-color: $warning;
    }
  }
  &[data="red"] {
    @include linear-gradient($danger-states, $danger);
    &:before {
      border-bottom-color: $danger;
    }
  }
  .user {
    padding-bottom: 20px;
    margin: 20px auto 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: $opacity-5;
    }
    .photo {
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 10px;
      border-radius: 50%;
      margin-left: 23px;
      box-shadow: $box-shadow-raised;
      @extend .animation-transition-general;
      img {
        width: $full-width;
      }
    }
    a {
      color: $white;
      text-decoration: none;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
      @extend .animation-transition-general;
    }
    .info {
      > a {
        display: block;
        line-height: 18px;
        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }
      .caret {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }
  // &:before {
  //   content: "";
  //   position: absolute;
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-bottom: 5px solid $primary;
  //   top: -5px;
  //   left: 40px;
  //   transform: translate(-50%);
  // }
}
.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}
.off-canvas-sidebar {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white;
      cursor: pointer;
    }
    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}
.main-panel {
  position: relative;
  float: right;
  width: $full-width;
  min-height: 100vh;
  border-top: 2px solid $primary;
  background: linear-gradient($background-black, $background-states-black);
  @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  &[data="blue"] {
    border-top: 2px solid $info;
  }
  &[data="green"] {
    border-top: 2px solid $success;
  }
  &[data="orange"] {
    border-top: 2px solid $warning;
  }
  &[data="red"] {
    border-top: 2px solid $danger;
  }
  &[data="primary"] {
    border-top: 2px solid $primary;
  }
  > .content {
    padding: 70px 30px 30px 284px;
    min-height: calc(100vh - 70px);
  }
  > .navbar {
    margin-bottom: 0;
  }
  .header {
    margin-bottom: 50px;
  }
}
.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}
.panel-header {
  height: $sidebar-width;
  padding-top: 80px;
  padding-bottom: 45px;
  background: #141e30;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c2646),
    color-stop(60%, #204065),
    to(#2a5788)
  );
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;
  .header {
    .title {
      color: $white;
    }
    .category {
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;
      a {
        color: $white;
      }
    }
  }
}
.panel-header-sm {
  height: 135px;
}
.panel-header-lg {
  height: 380px;
}
@include media-breakpoint-down(md) {
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: $sidebar-width;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition($sidebar-transition);
    @include transform-translate-x(-$sidebar-width);
    .sidebar-wrapper {
      height: 100vh;
    }
  }
  .minimize-sidebar {
    display: none;
  }
  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
    .sidebar {
      @include transition($sidebar-transition);
      @include transform-translate-x(0px);
      &:before {
        content: unset;
      }
    }
    body {
      position: relative;
      overflow-x: hidden;
    }
    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-$sidebar-width);
      }
      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }
      .bodyClick {
        right: $sidebar-width;
        left: auto;
      }
    }
  }
  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
  }
  .bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: $sidebar-width;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
  .wrapper {
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
  .main-panel {
    width: 100%;
    .content {
      padding-left: 30px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }
  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }
    .sidebar {
      width: 80px;
      .sidebar-wrapper {
        width: 100% !important;
      }
    }
    .sidebar {
      display: block;
      z-index: 1030;
      box-shadow: $sidebar-box-shadow;
      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }
      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .info > a > span,
        > .nav li > a {
          width: 50px;
          height: 50px;
          display: inline-flex;
          justify-content: center;
          > i {
            margin-right: 0;
            margin-left: 0;
          }
          > p {
            @include transform-translate-x(-25px);
            opacity: 0;
            display: none;
          }
        }
      }
      .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon {
        opacity: 1;
      }
    }
    .sidebar:hover {
      // width: $sidebar-width;
      // .logo {
      //   a.logo-normal {
      //     opacity: 1;
      //     @include transform-translate-x(0);
      //   }
      // }
      // .navbar-minimize {
      //   opacity: 1;
      // }
      // .sidebar-wrapper {
      //   width: $sidebar-width;
      //   > .nav li > a p,
      //   > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
      //   .user
      //     .info
      //     [data-toggle="collapse"]
      //     ~ div
      //     > ul
      //     > li
      //     > a
      //     .sidebar-normal,
      //   .user .info > a > span {
      //     @include transform-translate-x(0px);
      //     opacity: 1;
      //   }
      // }
      // .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon {
      //   opacity: 0;
      // }
    }
    .main-panel {
      > .content {
        padding-left: 104px;
      }
    }
    footer {
      padding-left: 104px;
    }
  }
  .navbar-minimize {
    button {
      margin-left: 10px;
      i {
        color: $black;
        font-size: 22px;
        font-weight: $font-weight-medium;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@include media-breakpoint-between(md, lg) {
  .wrapper {
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: $sidebar-width !important;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition($sidebar-transition);
    @include transform-translate-x(-$sidebar-width);
    .sidebar-wrapper {
      height: 100vh;
    }
  }
  .main-panel {
    @include transition($sidebar-transition);
  }
  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
    .sidebar {
      @include transition($sidebar-transition);
      @include transform-translate-x(0px);
      &:before {
        content: unset;
      }
    }
    body {
      position: relative;
      overflow-x: hidden;
    }
    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-$sidebar-width);
      }
      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }
      .bodyClick {
        right: $sidebar-width;
        left: auto;
      }
    }
  }
  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
  }
  .bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: $sidebar-width;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
  .main-panel {
    & .content {
      padding-left: 30px !important;
    }
  }

  .sidebar-mini {
    & .sidebar {
      & .logo {
        & a.logo-normal {
          opacity: 1;
          transform: translate3d(0px, 0, 0);
        }
      }
      & .sidebar-wrapper {
        & > .nav {
          & li {
            & > a {
              & p {
                opacity: 1;
              }
            }
            & a {
              & p {
                opacity: 1 !important;
                transform: translate3d(0px, 0, 0);
              }
            }
          }
        }
      }
    }
  }
  .minimize-sidebar {
    display: none;
  }
}
