.ant-drawer {
  z-index: 9999 !important;
}
.posWrapper .posHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--blackOpacity10);
}

.posHeader .posHeaderBtn {
  display: flex;
  gap: 15px;
}
.posHeader .posHeaderBtn svg {
  margin-left: 5px;
}
.posHeader .posHeaderBtn a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: underline;
}
.posHeader .posHeaderBtn a:hover {
  color: var(--primary);
}
.posHeader .posHeaderBtn svg path {
  transition: 0.2s all ease-in-out;
  fill: var(--dark);
}
.posHeader .posHeaderBtn a:hover svg path {
  fill: var(--primary);
}
.posSubHeader {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.posSubHeader .subHeading {
  margin-bottom: 0;
}
.posSubHeader .subButtonWrapper {
  display: flex;
  gap: 15px;
}
/* -------------category Details-------------------- */
.categoryDetailsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.categoryDetailsHeader .categoryDetailsHeaderLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
.categoryDetailsHeader .categoryDetailsHeaderLeft .subHeading {
  margin-bottom: 0;
}
.categoryDetails .commonButtonSmall {
  margin-right: 15px;
  margin-top: 30px;
}
/* -------------Product Model-------------------- */
.productItemsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.productItemsHeader .subHeadingpara .sComplete {
  color: var(--success);
  text-transform: capitalize;
  font-weight: 500;
}
.productItemsHeader .subHeadingpara .sPending {
  color: var(--pending);
}
.productItems {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.productItems .productItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productItems .productItem:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}
.productItems .productItem:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--blackOpacity10);
}
.productItems .productItem:not(:first-child) {
  padding-top: 15px;
}
.posOrderInfo .paymentDetails {
  border-top: 1px solid var(--blackOpacity10);
}
.posOrderInfo .paymentDetailsWrapper {
  background-color: var(--light);
  padding: 15px;
  border-radius: 10px;
}
.paymentDetailsWrapper .subHeading {
  margin-bottom: 20px;
}
.paymentDetailsWrapper .subInnerHeadingPara {
  font-weight: 400;
}

.paymentDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.paymentDetailsHeader p {
  margin-bottom: 0;
}

.paymentDetailsHeader svg {
  width: 20px;
  height: 100%;
}

.paymentDetailsBody {
  background-color: var(--light);
  padding: 15px;
  border-radius: 10px;
}

.paymentDetailsBody .paymentDetailsBodyContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.paymentDetailsBody .paymentDetailsBodyContent svg {
  cursor: pointer;
}

.paymentDetailsBody .paymentDetailsBodyContent:first-child {
  padding-top: 0;
}

.paymentDetailsBody .paymentDetailsBodyContent:last-child {
  padding-bottom: 0;
}

.paymentDetailsBody .paymentDetailsBodyContent a {
  color: var(--primary);
  margin-right: 5px;
}

.paymentDetailsBody .paymentDetailsBodyContent:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}

.qrContentWrapper .successFooter {
  margin-top: 30px;
}

.qrContentWrapper .successFooter a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: underline;
  margin-left: 5px;
}

/* -------------POS Category Model-------------------- */

.posCategoryModelInner {
  display: flex;
}

.posCategoryModelInner input[type="text"] {
  margin-top: 0;
  margin-right: 15px;
}

.posCategoryModelBtns {
  display: flex;
  gap: 10px;
}

.posCategoryModelAdded .posCategoryModelInner {
  margin-top: 15px;
}

.posCategoryModelAdded {
  margin-left: 15px;
}

/* -------------Paylinks Delete Model-------------------- */

/* --- extra theme --- */
.commonProfilePic {
  width: 45px;
  height: 45px;
}

.commonProfilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.photo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  /* margin: 0 auto; */
}

.photo img {
  width: 100%;
}

.ant-empty-normal .ant-empty-description {
  color: rgba(255, 255, 255, 0.7) !important;
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-thead > tr,
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td,
.ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-cell-fix-right,
.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background-color: #27293d;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover,
.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover,
.ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover
  > td:hover {
  background-color: #27293d;
  color: rgba(255, 255, 255, 0.7) !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0 15px 15px;
}

/* ------------light theme--------- */

.white-content .ant-empty-normal .ant-empty-description {
  color: rgba(34, 42, 66, 0.7) !important;
}

.white-content .ant-table-wrapper .ant-table,
.white-content .ant-table-wrapper .ant-table-thead > tr,
.white-content .ant-table-wrapper .ant-table-thead > tr > th,
.white-content .ant-table-wrapper .ant-table-thead > tr > td,
.white-content .ant-table-thead > tr > th,
.white-content .ant-table-wrapper .ant-table-cell-fix-right,
.white-content .ant-table-wrapper .ant-table-cell-fix-left,
.white-content .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background-color: white;
}

.white-content
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover,
.white-content
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover,
.white-content
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover
  > td:hover {
  background-color: white;
  color: rgba(34, 42, 66, 0.7) !important;
}

.white-content .wallet-adapter-dropdown-list-item {
  color: var(--white);
}

/* ------------Global/custom--------- */

.padd20 {
  padding: 20px;
}
