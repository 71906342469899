.ant-drawer {
  z-index: 9999 !important;
}

.posOrderInfo .paymentDetails {
  border-top: 1px solid var(--blackOpacity10);
}

.posOrderInfo .paymentDetailsWrapper {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
}

.paymentDetailsWrapper .subHeading {
  margin-bottom: 20px;
}
.paymentDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.paymentDetailsHeader p {
  margin-bottom: 0;
}

.paymentDetailsHeader svg {
  width: 20px;
  height: 100%;
}

.paymentDetailsBody {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
}

.paymentDetailsBody .subInnerHeading {
  margin-bottom: 0;
}

.paymentDetailsBody .paymentDetailsBodyContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.paymentDetailsBody .paymentDetailsBodyContent svg {
  cursor: pointer;
}

.paymentDetailsBody .paymentDetailsBodyContent:first-child {
  padding-top: 0;
}

.paymentDetailsBody .paymentDetailsBodyContent:last-child {
  padding-bottom: 0;
}

.paymentDetailsBody .paymentDetailsBodyContent a {
  color: var(--primary);
  margin-right: 5px;
}

.paymentDetailsBody .paymentDetailsBodyContent:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}

.qrContentWrapper .successFooter {
  margin-top: 30px;
}

.qrContentWrapper .successFooter a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: underline;
  margin-left: 5px;
}

/* -------------POS Category Model-------------------- */

.posCategoryModelInner {
  display: flex;
}

.posCategoryModelInner input[type="text"] {
  margin-top: 0;
  margin-right: 15px;
}

.posCategoryModelBtns {
  display: flex;
  gap: 10px;
}

.posCategoryModelAdded .posCategoryModelInner {
  margin-top: 15px;
}

.posCategoryModelAdded {
  margin-left: 15px;
}
