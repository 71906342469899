/* badges */
.badge {
  text-transform: uppercase;
  line-height: 12px;
  font-size: 0.75rem;
  border: none;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:not(:last-child) {
    margin-bottom: 4px;
  }
  & + .badge {
    margin-left: 4px;
  }
}
.badge-icon {
  padding: 0.4em 0.55em;
  i {
    font-size: 0.8em;
  }
}
